
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field, Form } from "vee-validate";
import { translate } from "@/core/plugins/i18n";
import { getModule } from "vuex-module-decorators";
import CashModule from "@/store/modules/sales copy/modules/cash";
import * as Validations from "yup";
import toCurrency from "@/core/filters/ToCurrency"
import OpenedCashRegisterMovent from "@/models/cash/OpenedCashRegisterMovent";
import Select from "@/components/forms/Select.vue"
import {VMoney} from 'v-money'
import OptionSelect from "@/models/shared/OptionSelect";
import PDFViewer from "@/components/modals/general/PDFViewer.vue";

export default defineComponent({
    components:{
         Field,
         Form,
         Select,
         PDFViewer
    },
    setup() {
        const form = ref();
    const openPDF = ref(HTMLButtonElement); 


        const money = ref(({//en algun momento se usara multilenguaje y aqui pueden aplicar los cambios
            decimal: '.',
            thousands: ',',
            prefix: '$ ',
            suffix: '',
            precision: 2,
            masked: false
        }))

        let numberValue =  ref(0);
        const moduleCash = getModule(CashModule);
         const infoCash = ref<OpenedCashRegisterMovent>({
            id: '------',
            cashierId: '------',
            cashierName: '--------------',
            cashRegisterId: '------',
            cashRegisterName: '--------------',
            closedDate: '------',
            closureDate: '------',
            initialBalance: 0,
            openingDate: '------',
            openningDate: '------',
            supervisorId: '------',
            opening: ''
        });

        // moduleCash.GET_INFO_CASH();
        moduleCash.GET_OPENED_CASH();

        //Schema
        const validationSchema = Validations.object().shape({
            cashRegisterMovementId: Validations.string().nullable().default('').required().label("cashRegisterMovementId"),
            observation: Validations.string().nullable().default(null).label("observation"),
            additionalFund: Validations.string()
                            .default('0')
                            .required()
                            .label("initialBalance")
        });

        
        //metodos
        const submitAdditionalFund = (data: any) => {
            let actionModule: Promise<any>;
            const numero = parseFloat(data.additionalFund.replace(/[^\d.]/g, ''));

            actionModule = moduleCash.ADDITIONAL_FUND({
                cashRegisterMovementId: data.cashRegisterMovementId,
                movement: numero,
                observation: data.observation
            });

            actionModule.then((isCorrect) => {
                if(isCorrect){
                    let actionModuleView: Promise<boolean>;
                    actionModuleView = moduleCash.GET_VIEW_ADDITIONAL(
                        isCorrect.id,
                    );

                    actionModuleView.then((isCorrect2) => {
                        if(isCorrect2){
                            if(openPDF.value) {
                                (openPDF.value as unknown as HTMLButtonElement).click();
                            }
                        }
                    });
                }
            });
        }

        const handleChange = (id: string) => {
            const info = lstmovementsAll.value.find(ac => ac.id == id);

            if(info)
              infoCash.value = info;

        }

        // const infoCashUser = computed(() => moduleCash.getUserInfoCash);
        const lstmovementsAll = computed(() => moduleCash.getMoments);
        const lstmovements = computed(() => moduleCash.getMomentsCombo);

        const infoCashUser = computed(() => moduleCash.getUserInfoCash);
        
        const pdf = computed(() => moduleCash.getbase64PDF);


        onMounted(() => {
                setCurrentPageBreadcrumbs(translate('BREADCRUMB.ADDITIONALFUND'), [translate('BREADCRUMB.MODULES')]);
                
                const idSelected = lstmovements.value[0]?.id
                form.value.setFieldValue('cashRegisterMovementId', idSelected);
                
        });

        watch(() => moduleCash.getMomentsCombo, async(currentValue: OptionSelect[]) => {//para usar el watch hay que hacerlo con base a un getter
          if(currentValue){

            const fristValue = currentValue[0];

            if(fristValue){
                form.value.setFieldValue('cashRegisterMovementId', fristValue.id);

                const info = lstmovementsAll.value.find(ac => ac.id == fristValue.id);

                if(info)
                    infoCash.value = info;
            }
          }
        })

        return {
            infoCashUser
            , validationSchema
            , infoCash
            ,lstmovementsAll
            ,lstmovements
            ,numberValue
            ,form
            ,money
            ,pdf
            ,openPDF

            , handleChange
            , toCurrency
            , submitAdditionalFund
        }
    }
    , directives:{
        money: VMoney
    }
})
